import React, { Fragment, useState } from "react"
import { connect } from 'react-redux';
import {basketActions, basketSelectors} from '../state/basket'
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import Layout from "../components/Layout";
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight,faAsterisk } from "@fortawesome/free-solid-svg-icons";
import style from "./checkout.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert, Button, Card, Container, Form, Media } from "react-bootstrap";
import {createPaymentIntent} from "../api/payments";
import { Field } from "../components/UI/Form/Field";
import { navigate } from 'gatsby';
import { Charity } from "../components/UI/Checkout/Charity";
import axios from "axios";
import { Link } from "gatsby";
import queryString from "query-string";
import {getTotalValue} from "../state/basket/selectors";

const termsConditions = `I confirm I agree with <a target='_blank' href="${process.env.WORDPRESS_TERMS_CONDITIONS_URL}">terms & conditions</a>`;
const marketingOptIn =  `We’d like to send you a thank you and keep in touch by email about our activities. By ticking this box, you agree to be contacted by Little Village and included on our mailing list. Your data will be kept in accordance with our <a target='_blank' href="${process.env.WORDPRESS_TERMS_DATA_POLICY}">data protection policy</a>`;
// TIP: dont call loadStripe within render moethod of component
//
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      color: "#495057",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#b6b5be",
      },
    },
    invalid: {
      color: "#fd5d65",
      iconColor: "#fd5d65",
    },
  },
}

const ErrorMessage = ({ children }) => (
    <Alert variant="danger">
        {children}
    </Alert>
);

const ResetButton = ({ onClick }) => (
    <button type="button" className="ResetButton" onClick={onClick}>
        <svg width="32px" height="32px" viewBox="0 0 32 32">
            <path
                fill="#FFF"
                d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
            />
        </svg>
    </button>
);

const CheckoutForm = ({paymentData, totalValue, resetBasket}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const [marketingCheckboxConfirmation, setMarketingCheckboxConfirmation] = useState(false);
    const [termsCheckboxConfirmation, setTermsCheckboxConfirmation] = useState(false);
    const [giftAidConfirmation, setGiftAidConfirmation] = useState(false);
    const [ formValidated, setFormValidated] = useState(false);
    const [scrollUp, setScrollUp] = useState(false);


    console.log('checkbox values',giftAidConfirmation, termsCheckboxConfirmation, marketingCheckboxConfirmation);

    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
        ev.complete ? setCardComplete(true) : setCardComplete(false);
      };


    const handleSubmit = async ev => {

        ev.preventDefault();

        // form validation
        const form = ev.currentTarget;

        if (form.checkValidity() === false) {
            ev.stopPropagation();
            setFormValidated(true);
            setScrollUp(false);
            return;
         } else {
            setFormValidated(false);
            setScrollUp(true);
        }


        const customerEmail = ev.target.email.value;
        const customerFirstName = ev.target.firstName.value;
        const customerLastName = ev.target.lastName.value;
        paymentData.receiptEmail = customerEmail;


        const billingDetails = {
            name: ev.target.firstName.value + " " + ev.target.lastName.value,
            email: customerEmail,
            phone: ev.target.phone.value,
            address: {
              city: ev.target.city.value,
              line1: ev.target.billingAddress1.value,
              line2: ev.target.billingAddress2.value,
              country: ev.target.country.value,
              postal_code: ev.target.postcode.value
            }
          };

        const cardElement = elements.getElement(CardElement);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (checkoutError) {
            elements.getElement('card').focus();
            return;
        }

        if (cardComplete) {
            setProcessing(true);
        }

        createPaymentIntent(paymentData, customerEmail)

            .then(response => {
                const secret = response.data;
                return secret;

            }).then((secret) => {
            stripe.confirmCardPayment(secret, {
                payment_method: {
                    card: cardElement,
                    //card: elements.getElement('card'),
                    billing_details: billingDetails
                }})

            .then(function(result) {
                if (result.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    console.error(result.error.message);
                    setProcessing(false);
                    navigate('/paymentFailure', {state: { 'errorMessage': result.error.message }})
                } else {
                    // The payment has been processed!
                    if (result.paymentIntent.status === 'succeeded') {
                        console.log('success');
                        resetBasket();

                        if (giftAidConfirmation) {
                            console.log('sending gift aid data');
                            const data = {
                                name: billingDetails.name,
                                addressLine1: billingDetails.address.line1,
                                addressLine2: billingDetails.address.line2,
                                city: billingDetails.address.city,
                                postcode: billingDetails.address.postal_code,
                                totalDonationAmount: totalValue
                            };
                            axios.post(
                                `${process.env.GIFT_AID_WORDPRESS_FORM_URL}`,
                                queryString.stringify(data)
                            ).then((giftAidFormResult) => {
                                    console.log('success submission of gift aid form', giftAidFormResult)
                                }
                            ).catch(() => {
                                console.error('failed submission of gift aid form')
                            })
                        }

                        if (marketingCheckboxConfirmation) {
                            const data = {
                                firstName: customerFirstName,
                                lastName: customerLastName,
                                email: customerEmail,
                                totalDonationAmount: totalValue
                            };

                            console.log('sending marketing data');

                            axios.post(
                                `${process.env.CHECKOUT_MARKETING_WORDPRESS_FORM_URL}`,
                                queryString.stringify(data)
                            ).then((marketingFormResult) => {
                                    console.log('success submission of marketing data form', marketingFormResult)
                                }
                            ).catch(() => {
                                console.error('failed submission of marketing data form')
                            })
                        }
                        navigate('/paymentSuccess', {state: { 'result': result }})
                    }
                }
            });
        });
    };


  const buttonClassnames = classNames('my-4',(!stripe || checkoutError || !termsCheckboxConfirmation || processing) ? 'disabled' : '');


    return (
    <Form  id="topForm" noValidate validated={formValidated} onSubmit={handleSubmit}>
                <h5  className="text-left font-weight-bold">Payment details</h5>
                <Card body>
                    <Row>
                        <Col>
                            <Field
                                label="First name"
                                id="firstName"
                                type="text"
                                placeholder="Jane"
                                required

                            />
                        </Col>
                        <Col>
                            <Field
                                label="Last name"
                                id="lastName"
                                type="text"
                                placeholder="Doe"
                                required
                            />
                        </Col>
                    </Row>

                    <Field
                        label="Email"
                        id="email"
                        type="email"
                        placeholder="janedoe@gmail.com"
                        required
                    />
                    <Field
                        label="Phone"
                        id="phone"
                        type="tel"
                        placeholder="(941) 555-0123"
                        required
                    />
                    <div className={`${style.cardContainer} my-4`}>
                        <CardElement options={CARD_OPTIONS}
                            onChange={handleCardDetailsChange}
                            /* onChange={(e) => {
                                setError(e.error);
                                setCardComplete(e.complete);
                            }}  */
                        />
                    </div>
                    { checkoutError &&
                        <ErrorMessage>{checkoutError}</ErrorMessage>
                    }
                </Card>

                <h5 className="text-left font-weight-bold mt-4">Billing address</h5>
                <Card body className="mb-4">
                <Form.Group>
                    <Form.Label htmlFor="country">Country</Form.Label>
                    <Form.Control as="select" id="country" name="country">
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                    </Form.Control>
                </Form.Group>
                    <Field
                        label="Billing address line 1"
                        id="billingAddress1"
                        type="text"
                        required
                    />
                    <Field
                        label="Apartment, suite, etc (optional)"
                        id="billingAddress2"
                        type="text"
                    />
                    <Row>
                        <Col>
                            <Field
                                label="City"
                                id="city"
                                type="text"
                                required
                            />
                        </Col>
                        <Col>
                        <Field
                                label="Postcode"
                                id="postcode"
                                type="text"
                                required
                            />
                        </Col>
                    </Row>

                    <Form.Check
                        type="checkbox"
                        id="confirmation"
                        required
                        label={
                          <div>
                            <FontAwesomeIcon icon={faAsterisk} className="text-danger mr-1 mb-2 fa-xs" />
                            <span dangerouslySetInnerHTML={{__html: termsConditions}} />
                          </div>
                        }
                        onClick={() => setTermsCheckboxConfirmation(!termsCheckboxConfirmation)}
                    />

                    <Form.Check
                        type="checkbox"
                        id="marketingConfirmation"
                        label={<span dangerouslySetInnerHTML={{__html: marketingOptIn}} />}
                        onClick={() => setMarketingCheckboxConfirmation(!marketingCheckboxConfirmation)}
                    />
                </Card>
                <Charity setGiftAidConfirmation={setGiftAidConfirmation} giftAidConfirmation={giftAidConfirmation} totalValue={totalValue} />
      {(scrollUp) ?
        <Button className={buttonClassnames} variant="primary" type="submit" size="lg" >
          { processing ?
            'Processing...'
            :
            <Fragment>Process payment <strong>£{totalValue}</strong> <FontAwesomeIcon icon={faChevronRight} className="ml-3" /></Fragment>
          }
        </Button>

        :

        <Button className={buttonClassnames} variant="primary" type="submit" size="lg" onClick={ () => {
          let elmnt = document.getElementById("topForm");
          elmnt.scrollIntoView(); }} >
          { processing ?
            'Processing...'
            :
            <Fragment>Process payment <strong>£{totalValue}</strong> <FontAwesomeIcon icon={faChevronRight} className="ml-3" /></Fragment>
          }
        </Button>
      }

            </Form>
    );
};




const CheckoutPage = ({ items, basket, totalValue, paymentData, resetBasket }) => {

    return (

        <Layout
            seoComponent={{ seoTitle: "Checkout", seoDescription: "Checkout" }}
            location={{ pathname: "checkout" }}// page-not-found is used not to display donate action in the checkout
        >
          <div className="container">
            <div className="mb-4 mt-5 mt-lg-11 pt-lg-4  pl-3 pl-xl-0 ">
                <FontAwesomeIcon icon={faChevronLeft} className={style.chevronIcon} />
                <a href="/basket" className="text-danger ml-2 font-weight-bold">Return to basket</a>
            </div>
            <div className="d-flex justify-content-between  pl-3 pl-xl-0 mb-3">
                <h2 id="topForm">Checkout</h2>
            </div>
          </div>

            <div className={`${style.bgImg}`}>
                <Container>
                    <Row className="pt-4">
                        <Col lg={{span: 8, order: 1}} className="order-2">
                            {/* options={ELEMENTS_OPTIONS} */}
                            <Elements stripe={stripePromise}>
                                <CheckoutForm paymentData={paymentData} totalValue={totalValue} resetBasket={resetBasket} />
                            </Elements>
                        </Col>
                        <Col lg={{span: 4, order: 2}} className="order-1">
                            <h5 className="text-left font-weight-bold">Your order</h5>
                            <Card body>
                                {basket.map((x, i) => (
                                    <Media className="mb-3" key={i}>
                                        <img width="50" src={x.item.image.sourceUrl} alt={x.item.image.altText} />
                                        <div className="d-flex justify-content-between w-100 ml-2">
                                            <div>
                                                <p className="mb-1"><strong>{x.item.name}</strong></p>
                                                <p className="mb-0"><small>x{x.count}</small></p>
                                            </div>
                                            <div>
                                                <p className="mb-0"><strong>{x.item.price}</strong></p>
                                            </div>
                                        </div>
                                    </Media>
                                ))}
                            </Card>
                            <Card className="mt-3" body>
                                <div className="d-flex justify-content-between align-items-center w-100 ">
                                    <p className="mb-0">Total</p>
                                    <h5 className="mb-0 font-weight-bold">
                                        £{totalValue}
                            </h5>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    items: basketSelectors.getItemsIdAndCount(state),
    basket: basketSelectors.getItems(state),
    paymentData: basketSelectors.getPaymentData(state),
    totalValue: basketSelectors.getTotalValue(state)
});

const mapDispatchToProps = {
    resetBasket: basketActions.resetBasket
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
