import React from 'react'
import { Form } from 'react-bootstrap';

export const Field = ({
    label,
    id,
    type,
    placeholder,
    required,
    value,
    onChange,
}) => (

        <Form.Group>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <Form.Control
                id={id}
                name={id}
                type={type}
                /* value={value} */
                {...placeholder !== undefined ? placeholder={placeholder} : ''}
                {...required !== undefined ? required={required} : ''}
                {...onChange !== undefined ? onChange={onChange} : ''}
            />
        </Form.Group>
    );
