import React from 'react'
import { Card, Form } from 'react-bootstrap'
import style from "./Charity.module.scss"
import logo from "../../../images/gift_Aid_UK_Logo.svg"

export const Charity = ({totalValue, setGiftAidConfirmation, giftAidConfirmation}) => {

    const giftTotal = parseFloat((totalValue * 0.05) + parseFloat(totalValue)).toFixed(2);

    return (
        <Card body className={`${style.card}`}>
        <img className="text-center m-auto d-block" src={logo} alt="gift aid logo" width="190"/>
            <h5 className="text-center">Turn your £{totalValue} donation into <strong>£{giftTotal}</strong> at no extra cost!</h5>
            <Card body className={style.bg}>
                <p className="lead">By ticking the box below, you’re agreeing that:</p>
                <ul className={`${style.list} lead`}>
                    <li>This is my own money. I am not paying in donations made by a third party.</li>
                    <li>This donation is not made as part of a sweepstake, raffle or lottery and I am not receiving anything in return for it.</li>
                    <li>I am a UK taxpayer.</li>
                </ul>
            </Card>

            <Form.Check
                type="checkbox"
                id="charityConfirmation"
                label="Yes, add Gift Aid"
                className="lead"
                onClick={() => setGiftAidConfirmation(!giftAidConfirmation)}
                    />
        </Card>
    )
}
